// Login.js

import React, {  useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField } from '@mui/material';

import './Register.css'; // Import the external CSS file
import { useNavigate } from 'react-router-dom';



const Register = () => {
  const Navigate = useNavigate()
  const [loading, setLoading] = useState(false);
 


  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      email: '',
      mobile: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('name is required'),
      password: Yup.string().required('Password is required'),
      email: Yup.string().required('email is required'),
      mobile: Yup.string().required('mobile is required'),
    }),
    onSubmit:async(values, {resetForm})=> {
      
      
      setLoading(true);

        const response = await fetch("https://databack-tumt.vercel.app/register",{
               method: 'POST',
               headers: {
       'Content-Type': 'application/json',
               },
               body: JSON.stringify(values),
             })
       
             const data = await response.json();
       
       
        if(data.success){
       
        alert(data.message)
        
       resetForm()
       Navigate('/login')
       
        
        }else{
           alert(data.error)
        }

        
          setLoading(false);
       
    },
  });

  return (
    <div className='main-login'>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          className="formField"
          fullWidth
          id="name"
          name="name"
          label="name"
          variant="outlined"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
        className="formField"
        fullWidth
        id="email"
        name="email"
        label="email"
        type="email"
        variant="outlined"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />

      <TextField
      className="formField"
      fullWidth
      id="mobile"
      name="mobile"
      label="mobile"
      type="mobile"
      variant="outlined"
      value={formik.values.mobile}
      onChange={formik.handleChange}
      error={formik.touched.mobile && Boolean(formik.errors.mobile)}
      helperText={formik.touched.mobile && formik.errors.mobile}
    />
        <TextField
          className="formField"
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        
        <Button
          className="submitButton"
          type="submit"
          variant="contained"
          fullWidth
        >
          Login
        </Button>
      </form>
    </div>
  );
};

export default Register;
