import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './Component/Login/Login'
import DashBoard from './Component/DashBoard/DashBoard'
import MyContextProvider from './Uttis/MyContextProvider'
import Register from './Component/Register/Register'
import Admin from './Component/Admin/Admin'
import Accept from './Component/Accept/Accept'


const App = () => {
  return (
    <div>

    <BrowserRouter>
    <MyContextProvider>
    <Routes>
    <Route path='/login' element={<Login/>} />
    <Route path='/register' element={<Register/>} />
    <Route path='/admin' element={<Admin/>} />
    <Route path='/accept' element={<Accept/>} />
    <Route path='/dashboard/:username' element={<DashBoard/>} />


    
    
    </Routes>
    </MyContextProvider>
    </BrowserRouter>
    
    </div>
  )
}

export default App