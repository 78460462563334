// Accept.js

import React, { useContext, useEffect, useState } from 'react';
import './Accept.css';
import MyContext from '../../Uttis/MyContext';

const Accept = () => {
  const [enquiryData, setEnquiryData] = useState([]);
  const [loading, setLoading] = useState(true);
 

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await fetch('https://databack-tumt.vercel.app/accept');
      const data = await response.json();
      setEnquiryData(data);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
      setError('Error fetching attendance data. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const handleDelete = async (name, work) => {
   
      // Send a request to the backend to update the database
      const response = await fetch('https://databack-tumt.vercel.app/delete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          work,
        }),
      });

      const data = await response.json();

      if (data.success) {
        // Remove the accepted item from the local state
        alert(data.message)
        
      } else {
        console.error( data.error);
        // Handle the error as needed
      }
    
  };


  

  return (
    <div className='check-attendance'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>

        <table  className='enquiry-table'>
        <thead>
        <tr>
          <th>Name</th>
          <th>Work</th>
          <th>Date</th>
          <th>Link</th>
          <th>Action</th>
        </tr>
      </thead>
          {enquiryData
           
            .map((item) => (
            
                
            
              <tbody>
                <tr>
                  <td>{item.name}</td>
                  {item.works
                    .sort((a, b) => (a.date) -(b.date))
                    
                    .filter((workItem) => workItem.link && workItem.link.length !==0 && workItem.unison!=='veer')
                    .map((workItem) => (
                    <React.Fragment>
                      <td>{workItem.work}</td>
                      <td>{workItem.date}</td>
                      <td>{workItem.link}</td>
                      <td>
                      <button onClick={() => handleDelete(item.name, workItem.work)}>Yes</button>
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              </tbody>
           
          ))}

          </table>
        
          </>
      )}
    </div>
  );
};

export default Accept;
