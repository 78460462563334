import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, CircularProgress } from '@mui/material';
import './Login.css'; // Import the external CSS file
import { useNavigate } from 'react-router-dom';
import MyContext from '../../Uttis/MyContext';

const Login = () => {
  const { setIsAuthenticated } = useContext(MyContext);
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      try {
        const response = await fetch('https://databack-tumt.vercel.app/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        const data = await response.json();

        if (data.success) {
          resetForm();
          setIsAuthenticated(true);
          Navigate(`/dashboard/${data.data}`);
        } else {
          alert(data.error);
        }
      } catch (error) {
        console.error('Error during login:', error);
        alert('An error occurred during login. Please try again.');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className='main-login'>
      <h2 className='lo-h'>V-Ex Tech Solution</h2>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          className='formField'
          fullWidth
          id='email'
          name='email'
          label='Email'
          variant='outlined'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          className='formField'
          fullWidth
          id='password'
          name='password'
          label='Password'
          type='password'
          variant='outlined'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button
          className='submitButton'
          type='submit'
          variant='contained'
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color='inherit' /> : 'Login'}
        </Button>
      </form>
    </div>
  );
};

export default Login;
