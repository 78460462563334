import React, { useEffect, useState } from 'react'
import MyContext from './MyContext'
import { useNavigate } from 'react-router-dom';


const MyContextProvider = ({children}) => {
 
const Navigate = useNavigate()

    const [isAuthenticated, setIsAuthenticated] = useState(false);


    
    const [userData, setUserData] = useState(null);
  
 
    

  
  

  return (
    <MyContext.Provider value={{userData,setUserData,isAuthenticated, setIsAuthenticated}} >
      {children}
    </MyContext.Provider>
  )
}

export default MyContextProvider