// Register.js

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "./Admin.css"
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Admin = () => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  

  const formik = useFormik({
    initialValues: {
      name: '',
      date: '',
      work: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      date: Yup.string().required('Date is required'),
      work: Yup.string().required('Work is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      const response = await fetch('https://databack-tumt.vercel.app/admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (data.success) {
        alert(data.message);
        resetForm();
       
      } else {
        alert(data.error);
      }
      setLoading(false)
    },
  });

  return (
    <div className='main-login'>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          className='formField'
          fullWidth
          id='name'
          name='name'
          label='Name'
          variant='outlined'
         
         
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <TextField
          className='formField'
          fullWidth
          id='date'
          name='date'
          label='Date'
          type='date'
          variant='outlined'
          value={formik.values.date}
          onChange={formik.handleChange}
          error={formik.touched.date && Boolean(formik.errors.date)}
          helperText={formik.touched.date && formik.errors.date}
        />

        <TextField
          className='formField'
          fullWidth
          id='work'
          name='work'
          label='Work'
          variant='outlined'
          multiline
          rows={6}
         
          value={formik.values.work}
          onChange={formik.handleChange}
          error={formik.touched.work && Boolean(formik.errors.work)}
          helperText={formik.touched.work && formik.errors.work}
        />

        <Button className='submitButton' type='submit' variant='contained' fullWidth>
          Register
        </Button>
      </form>
    </div>
  );
};

export default Admin;
