import React, { useContext, useEffect, useState } from 'react';
import MyContext from '../../Uttis/MyContext';
import './DashBoard.css';
import { Link, useParams } from 'react-router-dom';

const Dashboard = () => {
  const { username } = useParams();

  const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useContext(
    MyContext
  );
  const [showTasks, setShowTasks] = useState(true);
  const [completedTasks, setcompletedTasks] = useState(false);
  const [link, setLink] = useState('');
  const [pendingNotification, setPendingNotification] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetch(`https://databack-tumt.vercel.app/user/${username}`);
      const data = await response.json();

      if (data.success) {
        setUserData(data.data);
        // Check if there are pending tasks and show notification if true
        setPendingNotification(data.data.works.some((task) => task.unison !== 'veer'));
      } else {
        console.error('Error fetching user data');
      }
    };

    fetchUserData();
  }, [username]);

  const toggleTasks = () => {
    setShowTasks(true);
    setcompletedTasks(false);
  };

  const toggleComplete = () => {
    setcompletedTasks(true);
    setShowTasks(false);
  };

  const handleLinkSubmit = async (name, work, date) => {
    if (link.length === 0) {
      alert('Please enter a link before submitting.');
      return;
    }

    const response = await fetch('https://databack-tumt.vercel.app/submitLink', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        work,
        date,
        link,
      }),
    });

    const data = await response.json();

    if (data.success) {
      alert(data.message);
    } else {
      alert(data.error);
    }
  };

  if (!isAuthenticated) {
    // You can redirect to the login page or show an authentication message
    return <div className='login-p'>Please login to access the dashboard.<br/> <Link to='/login'>click here to login</Link></div>;
  }

  return (
    <div className='dashboard-main'>
      <div className='dash-main'>
        <span className='dash'>{username}</span>
        <span className='dash'>V-Ex Tech Solution</span>

        <span className='dash' onClick={() => setIsAuthenticated(false)}>
          Logout
        </span>
      </div>
      <div className='dashboard-container'>
        <div className='dashboard-box' onClick={toggleTasks}>
          <h5>Total Works</h5>
        </div>

        <div className='dashboard-box' onClick={toggleTasks}>
          <h5>Pending Works</h5>
        </div>

        <div className='dashboard-box' onClick={toggleComplete}>
          <h5>Work Done</h5>
        </div>
      </div>

      {showTasks && (
        <div className='dashboard-tasks'>
          <h2 className='dash-h'>Today's Work</h2>
          <ol>
            {userData && userData.works ? (
              userData.works
                .filter((task) => task.unison !== 'veer')
                .map((task, index) => (
                  <li key={index}>
                    <span>Date: {task.date}</span>
                    <br />
                    <br />
                    <span style={{ fontSize: '20px' }}>Work:</span>
                    <pre>{task.work}</pre>
                    <input
                      type='text'
                      placeholder='enter link here'
                      onChange={(e) => setLink(e.target.value)}
                      required
                    />
                    <button onClick={() => handleLinkSubmit(username, task.work, task.date)}>
                      Submit
                    </button>
                  </li>
                ))
            ) : (
              <p>No Works available</p>
            )}
          </ol>
        </div>
      )}

      {completedTasks && (
        <div className='dashboard-tasks'>
          <h2 className='dash-h'>Completed Work</h2>
          <ol>
            {userData && userData.works ? (
              userData.works
                .filter((task) => task.unison === 'veer')
                .map((task, index) => (
                  <li key={index}>
                    <span>Date: {task.date}</span>
                    <br />
                    <br />
                    <span style={{ fontSize: '20px' }}>Work: </span> &nbsp; Completed
                    <pre>{task.work}</pre>
                  </li>
                ))
            ) : (
              <p>No Works available</p>
            )}
          </ol>
        </div>
      )}

      {pendingNotification && (
        <div className='pending-notification'>
          <p>There are pending tasks. Please review and complete them....</p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
